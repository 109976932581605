<template>
  <div
    class="flex-1 relative focus:outline-none w-full flex py-4 sm:py-6 sm:px-8 max-w-screen-2xl mx-auto"
  >
    <div class="mr-8 hidden lg:block w-2/12 max-w-56 flex-shrink-0">
      <slot name="leftSidebar" />
    </div>
    <div class="flex flex-col flex-1 max-w-full">
      <slot />
    </div>
    <div v-if="!!slots.rightSidebar" class="w-3/12 hidden lg:block ml-8">
      <slot name="rightSidebar" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
const slots = useSlots()
</script>
